import { startCase as _startcase } from 'lodash-es';
export function randomInt(a = 1, b = 0) {
  const lower = Math.ceil(Math.min(a, b));
  const upper = Math.floor(Math.max(a, b));
  return Math.floor(lower + Math.random() * (upper - lower + 1));
}

export function* chunk<T>(array: Array<T>, size: number) {
  for (let i = 0; i < array.length; i += size) {
    yield array.slice(i, i + size);
  }
}

export function uniq<T>(input: Array<T>) {
  return [...new Set(input)];
}

export function capitalize(string?: string) {
  return string ? string.charAt(0).toUpperCase() + string.slice(1).toLowerCase() : '';
}

/*
 * Converts a string to start case (e.g. 'fooBar' -> 'Foo Bar')
 * Initial letters are capitalized
 * Continuous capital letters are treated as one word (e.g. 'FOOBar' -> 'FOO Bar')
 */
export function startCase(input: string): string {
  return input
    .replaceAll(/([a-z])([A-Z])/g, '$1 $2') // Add space between camelCase
    .replaceAll(/[^a-zA-Z\s]/g, ' ') // Remove non a-z, non A-Z, and non whitespace characters
    .replaceAll(/\s+/g, ' ') // Remove multiple spaces
    .replaceAll(/([A-Z])([A-Z][a-z])/g, '$1 $2') // Add space between capital letter and following group of capital letter and lowercase letter
    .trim()
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
}
