import config from '../../config';
import { handleErrors } from '../api';
import { apiKeyName } from '../constants';
import { client } from '../galaxyClient';

export async function getJWTToken(firebaseToken: string) {
  const url = `${config.apiUrl}/authentication/v2/firebase?access_token=${firebaseToken}&skipUserCreation=true`;

  const response = await fetch(url);

  if (response.status === 401) {
    const body = await response.json();
    if (body && body.code == 103) {
      throw new Error('missing_email');
    }

    if (body && body.code == 106) {
      throw new Error(body.message);
    }

    throw new Error('Client error: 401 Unauthorized');
  }

  if (response.status === 404) {
    throw new Error('User not found');
  }

  if (!response.ok) {
    await handleErrors(response);
  }

  return await response.json();
}

export async function getMachineTokens(partnerId: string) {
  const { data } = await client.authentication.GET('/v2/machine-tokens/', {
    params: {
      query: {
        partnerId,
      },
    },
  });
  return data;
}

export async function createMachineTokens(
  partnerId: string,
  partnerName: string,
  donationProgramId?: string,
) {
  const { data } = await client.authentication.POST('/v2/machine-tokens/', {
    body: {
      name: `${apiKeyName}-${partnerName}`,
      partnerId,
      roles: ['PARTNER_API'],
      donationProgramId,
    },
  });
  return data;
}

export async function deleteMachineTokens(tokenId: number) {
  await client.authentication.DELETE('/v2/machine-tokens/{id}', {
    params: {
      path: {
        id: tokenId,
      },
    },
  });
}
