import config from '../../config';
import { getToken } from '../token';
import qs from 'qs';
import * as Sentry from '@sentry/react';

import { FetchError } from '../../types/entities';

export async function handleErrors(response: Response) {
  let message = '';
  let json;
  try {
    json = await response.json();
    message = JSON.stringify(json);
  } catch (_) {
    message = `${response.statusText}`;
  }

  let errorType = 'Server error';
  if (response.status >= 400 && response.status < 500) {
    errorType = 'Client error';
  }

  const error = new FetchError(
    `${errorType}: ${response.status} ${message}`,
    errorType,
    response.status,
    json,
  );

  Sentry.captureException(error);

  throw error;
}

type QueryParams =
  | string
  | number
  | Record<string, string | string[] | number | number[] | boolean | undefined>;

type RequestOptions = {
  query?: QueryParams;
  method?: 'GET' | 'POST' | 'PUT' | 'DELETE';
  headers?: Record<string, string>;
  body?: string | FormData;
  fullUrl?: boolean;
};

async function fetcher(url: string, options: RequestOptions = {}) {
  const headers: RequestOptions['headers'] = {
    Authorization: `Bearer ${getToken()}`,
    ...options.headers,
  };

  if (options.body && !headers['Content-Type'] && !(options.body instanceof FormData)) {
    headers['Content-Type'] = 'application/json';
  }

  if (options.query) {
    const query = qs.stringify(options.query, { indices: false });
    url = query ? `${url}?${query}` : url;
  }

  const response = await fetch(url, {
    method: options.method || 'GET',
    headers,
    body: options.body,
    credentials: 'include',
  });

  if (!response.ok) {
    await handleErrors(response);
  }

  if (response.status === 204) {
    return;
  }

  if (response.headers?.get('content-type')?.includes('application/json')) {
    return await response.json();
  }

  return await response.text();
}

export async function galaxyFetcher(path: string, options: RequestOptions = {}) {
  return await fetcher(`${config.apiUrl}${path}`, {
    ...options,
    headers: { 'x-application-name': 'alliance', ...options.headers },
  });
}
