import { Entry } from 'contentful';
import { TypeBlogPostSkeleton, TypeProfileSkeleton } from '../../@types/generated';
import { LOCALES } from '../lib/constants';
import {
  AccountingTypes,
  AuthenticationTypes,
  ContentTypes,
  PartnersTypes,
  ResponseData,
  UsersTypes,
} from '../lib/galaxyClient';

export enum UserStatus {
  ANONYMOUS = 'ANONYMOUS',
  ACTIVE = 'ACTIVE',
  SUSPENDED = 'SUSPENDED',
  PENDING = 'PENDING',
}

export type CurrentUser = ResponseData<UsersTypes, '/v2/me/', 'get'>;

export type Partner = ResponseData<PartnersTypes, '/v2/partners/{id}', 'get'>;

export type PartnerInvite = {
  email: string;
  firstName: string;
  isAdminInvite: boolean;
};

export type PartnerInvoiceStatus = PartnerInvoice['status'];

export type PartnerInvoice = ResponseData<
  AccountingTypes,
  '/v2/partners/{partnerId}/invoices/{id}',
  'get'
>;

export type Currency = {
  code: string;
  name: string;
  symbol: string;
  cents: number;
  providers?: string[];
};

export enum CategoryStatus {
  ACTIVE = 'ACTIVE',
  DELETED = 'DELETED',
  ARCHIVED = 'ARCHIVED',
}

export type Category = ResponseData<ContentTypes, '/v2/categories/{id}', 'get'>;

export type Location = ResponseData<ContentTypes, '/v2/locations/', 'get'>['result'][0];

export type LocationCountry = {
  code: Location['code'];
  country: Exclude<Location['country'], undefined>;
};

export type LocationWater = {
  code: Location['code'];
  water: Exclude<Location['water'], undefined>;
};

export type Impacter = ResponseData<ContentTypes, '/v2/impacters/{impacterId}', 'get'>;

type LocalizedString = Partial<Record<Locales, string>>;

export type PricelessPlanetOptions = {
  type: 'PPC';
  color: string;
  textColor: string;
  heroImage?: string | null;
  heroOverlayPercentage?: number;
  heroColorType?: 'dark' | 'light';
  heroImageCredit?: string;
  heroImageCurrentFile?: File | null;
  partnerLogo?: string | null;
  partnerLogoCurrentFile?: File | null;
  preamble: string;
  preambleByLocale?: LocalizedString;
  title: string;
  titleByLocale?: LocalizedString;
  hideDonationModule?: boolean;
  categoryIds?: Category['id'][];
  initiativeIds?: Initiative['id'][];
  termsAndConditions?: string;
  termsAndConditionsByLocale?: LocalizedString;
  CTAText?: LocalizedString;
  CTALink?: LocalizedString;
};

export type Locales = typeof LOCALES[number];

export type DonationProgram = ResponseData<
  PartnersTypes,
  '/v2/partners/{partnerId}/donation-programs/{id}',
  'get'
>;
export type DonationProgramStatus = 'ACTIVE' | 'DELETED';

export const COMMUNITY_PROGRAM_TYPES: DonationProgram['type'][] = [
  'CONSUMER_PAID_DONATION',
  'ONE_TIME_DIRECT_PAYMENT_USER_DONATION',
  'ONE_TIME_INVOICED_CONSUMER_PAID_DONATION',
];

export const CORPORATE_PROGRAM_TYPES: DonationProgram['type'][] = [
  'PARTNER_DONATION',
  'ONE_TIME_INVOICED_DONATION',
  'FIXED_AMOUNT',
  'TOP_UP',
];

export type MapWidgetType = {
  id: string;
  createdAt: Date;
  updatedAt: Date;
  name: string;
  type: string;
  settings: {
    publicKey: string;
    oceanColor: string;
    placesColor: string;
    groundColor: string;
    headerColor: string;
    textColor: string;
    arrowColor: string;
    arrowBackgroundColor: string;
    cardColor: string;
    dotStyle: DotStyle;
  };
  version: number;
};

export enum DotStyle {
  round = 'round',
  square = 'square',
}

export type StatisticsWidgetType = {
  id: string;
  createdAt: Date;
  updatedAt: Date;
  name: string;
  type: string;
  settings: {
    publicKey: string;
    backgroundColor: string;
    headerColor: string;
    textColor: string;
  };
  version: number;
};

export type TickerWidgetType = {
  id: string;
  createdAt: Date;
  updatedAt: Date;
  name: string;
  type: string;
  settings: {
    goalAmount: number;
    progressBarColor: string;
    progressBarFillColor: string;
    publicKey: string;
    backgroundColor: string;
    headerText: string;
    currencyCode: string;
    textColor: string;
  };
  version: number;
};

export type WidgetType = MapWidgetType | StatisticsWidgetType | TickerWidgetType;

export enum WidgetTypes {
  MAP_WIDGET = 'map',
  STATISTICS_WIDGET = 'statistics',
  TICKER_WIDGET = 'ticker',
}

export type AggregatedPartnerStatistics = ResponseData<
  AccountingTypes,
  '/v2/partners/{partnerId}/donations/statistics',
  'get'
>['result'];

type APIMachineToken = ResponseData<AuthenticationTypes, '/v2/machine-tokens/{id}', 'get'>;
export type MachineToken =
  | APIMachineToken
  // Created by src/hooks/useMachineTokens.ts#L47
  | { id: number; name: string; token: string; status: 'ACTIVE'; expires: Date };
export type PaymentRequest = ResponseData<
  AccountingTypes,
  '/v2/providers/partner/payment-requests/{id}',
  'get'
>;

export type PaymentRequestStatus = PaymentRequest['status'];

type ShopifyWebhookRuleCondition = {
  fieldName: 'tags';
  comparison: 'isEqualTo';
  value: string;
};

export type ShopifyWebhookRule = {
  id: string;
  type: 'FIXED_AMOUNT' | 'PERCENTAGE';
  value: number;
  conditions: ShopifyWebhookRuleCondition[];
};

export type ShopifyConfiguration = {
  id: string;
  status: 'ACTIVE' | 'INACTIVE';
  partnerId: Partner['id'];
  donationProgramId: DonationProgram['id'];
  shopifyDomain: string;
  webhookHost?: string;
  donationRules: ShopifyWebhookRule[];
  APISecretKey: string;
  adminAPIAccessToken: string;
};

export type NewShopifyConfiguration = Omit<
  ShopifyConfiguration,
  'id' | 'partnerId' | 'donationProgramId'
>;

export type CategoryAllocation = ResponseData<
  AccountingTypes,
  '/v2/allocations/categories/{categoryId}',
  'get'
>[number];

export type Initiative = ResponseData<ContentTypes, '/v2/initiatives/{initiativeId}', 'get'> & {
  plainTitle?: string;
  plainTagline?: string;
  plainDescription?: string;
};

export type Approach = ResponseData<ContentTypes, '/v2/approaches/{id}', 'get'> & {
  plainTitle?: string;
};

export class FetchError extends Error {
  public type: string;
  public status: number;
  public body?: { message?: string };

  constructor(message: string, type: string, status: number, body?: { message?: string }) {
    super(message);

    this.type = type;
    this.status = status;
    this.body = body;
  }
}

export type PartnerProfile = {
  id: string;
  name: string;
  availableCategoryIds: string[];
  rootCampaignPageUrl: string;
  extraAffiliation: string | null;
};

export type UploadableImageTypes =
  | 'logo'
  | 'campaign-image'
  | 'campaign-page-hero-image'
  | 'campaign-page-partner-logo';

export type PostType = 'EDITORIAL' | 'IMPACTER' | 'INITIATIVE';
export type PostStatus = 'DELETED' | 'DRAFT' | 'PENDING' | 'PUBLISHED' | 'SCHEDULED';
export type VideoResource = {
  mimeType: string;
  orientation?: 'landscape' | 'portrait';
  src: string;
};
export type PostAsset = {
  id: number | string; // video id or image id
  impacterId: string;
  type: 'IMAGE' | 'VIDEO' | 'TEXT' | 'DOCUMENT';
  video?: {
    key: string;
    resources: VideoResource[];
    posters: { main?: string; animation?: string; firstFrame?: string; mainThumbnail?: string };
    metaData: {
      ratio?: number;
      durationInMs?: number;
      hasAudioTrack?: boolean;
      orientation?: 'landscape' | 'portrait';
      originalHeight?: number;
      originalWidth?: number;
      originalMimeType?: string;
    };
    status?: 'PUBLISHED' | 'PENDING PUBLISHING' | 'SCHEDULED';
  };
  image?: {
    key: string;
    src: string;
    metaData: { originalMimeType: string; originalHeight?: number; originalWidth?: number };
    thumbnailSrc?: string;
  };
  document?: {
    key: string;
    id: string;
    posters?: {
      main?: string;
      thumbnailSrc?: string;
    };
    metaData: {
      originalName: string;
      originalMimeType: string;
      sizeInBytes: number | null;
    };
    poster: {
      src: string | null;
      thumbnailSrc: string | null;
    };
  };
};

export type Post = ResponseData<ContentTypes, '/v2/posts/{postId}', 'get'>;

const AssetTypes = {
  IMAGE: 'IMAGE',
  VIDEO: 'VIDEO',
  DOCUMENT: 'DOCUMENT',
} as const;
export type AssetType = typeof AssetTypes[keyof typeof AssetTypes];

export function isAssetType(type?: string | null): type is AssetType {
  return Object.values(AssetTypes).includes(type as never);
}

export type Asset = ResponseData<ContentTypes, '/v2/assets/{assetId}', 'get'>;

export type Video = ResponseData<ContentTypes, '/v2/videos/{id}', 'get'>;

export const ImpactFundsTabs = {
  about: 'about',
  initiatives: 'initiatives',
  media: 'media',
  updates: 'updates',
  resources: 'resources',
  funding: 'funding',
} as const;
export type ImpactFundsTab = typeof ImpactFundsTabs[keyof typeof ImpactFundsTabs];

export function isImpactFundsTab(type?: string | null): type is ImpactFundsTab {
  return Object.values(ImpactFundsTabs).includes(type as never);
}

export const InitiativeTabs = {
  about: 'about',
  updates: 'updates',
  media: 'media',
  resources: 'resources',
} as const;
export type InitiativeTab = typeof InitiativeTabs[keyof typeof InitiativeTabs];

export function isInitiativeTab(type?: string | null): type is InitiativeTab {
  return Object.values(InitiativeTabs).includes(type as never);
}

export type FeaturedArticleType = Entry<TypeBlogPostSkeleton, 'WITHOUT_UNRESOLVABLE_LINKS', string>;

export function isFeaturedArticle(
  featuredArticle?: FeaturedArticleType,
): featuredArticle is FeaturedArticleType {
  return !!featuredArticle;
}

export type Profile = Entry<TypeProfileSkeleton, 'WITHOUT_UNRESOLVABLE_LINKS', string>;

export type Pillar = ResponseData<ContentTypes, '/v2/pillars/', 'get'>['result'][number] & {
  plainTitle?: string;
};

export type ContributionRow = PaymentRequest & {
  streamName: string;
  type: string;
  formattedStatus: string;
  statusColor: string;
  fundNames: string;
  amount: string;
  formattedDate: string;
};
